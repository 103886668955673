<template>
  <div class="item-change-container rounded-[10px] bg-white p-20">
    <div class="mb-20 flex justify-between">
      <div class="flex items-center">
        <i class="iconfont icon-laba text-20 text-gray"></i>
        <span class="mx-5">{{ $t('饰品异动') }}</span>
        <el-tooltip :content="$t('点击下方平台名称，即可前往对应平台的饰品详情页。')" placement="top" effect="dark">
          <i class="iconfont icon-kongxinwenhao text-18"></i>
        </el-tooltip>
      </div>
      <div class="text-gray">
        <a :href="localePath('/changes')">{{ $t('查看更多') }}>></a>
      </div>
    </div>
    <div class="flex justify-between">
      <!-- <div class="vertical-divider mr-10 h-full"></div> -->
      <div class="right-container w-full pl-20">
        <template v-for="(item, index) in props.changeData" :key="item.changeTime">
          <div v-if="index < 6" class="item-change-item relative border-b border-[#EFF1F5] py-20">
            <div class="mb-10">
              <span class="mr-10">{{ formatDayText(item) }}</span>
              <a :href="item.link" target="_blank" class="text-gray-light">
                <i :class="`iconfont icon-${item?.platform?.toLowerCase()}`"></i>
                {{ item.platform }}
              </a>
            </div>
            <div class="flex justify-between">
              <div class="flex">
                <div class="item-img mr-10 flex h-68 w-100 items-center rounded-[10px] bg-[#f7f9ff] px-5">
                  <img :src="item?.imageUrl" alt="" />
                </div>
                <div>
                  <div class="mb-5">
                    <a target="_blank" :href="localePath(`/cs2/${item.marketHashName}?platform=${item.platform}`)">
                      {{ item.name }}
                    </a>
                  </div>
                  <div class="flex">
                    <div>
                      <div class="text-gray-light">{{ $t('在售价') }}</div>
                      <div :class="item.changeType === 1 ? 'text-[#F87600]' : ''">
                        {{ moneyUnit }} {{ currencyTransform(item.beforeSellPrice) }} >> {{ currencyTransform(item.sellPrice) }}
                      </div>
                    </div>
                    <div class="vertical-divider mx-10"></div>
                    <div>
                      <div class="text-gray-light">{{ $t('在售数') }}</div>
                      <div :class="item.changeType === 2 ? 'text-[#F87600]' : ''">{{ item.beforeSellCount }} >> {{ item.sellCount }}</div>
                    </div>
                    <div class="vertical-divider mx-10"></div>
                    <div>
                      <div class="text-gray-light">{{ $t('求购价') }}</div>
                      <div :class="item.changeType === 3 ? 'text-[#F87600]' : ''">
                        {{ moneyUnit }} {{ currencyTransform(item.beforePurchasePrice) }} >> {{ currencyTransform(item.purchasePrice) }}
                      </div>
                    </div>
                    <div class="vertical-divider mx-10"></div>
                    <div>
                      <div class="text-gray-light">{{ $t('求购数') }}</div>
                      <div :class="item.changeType === 4 ? 'text-[#F87600]' : ''">{{ item.beforePurchaseCount }} >> {{ item.purchaseCount }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-200 items-center pl-20">
                <mytooltip>
                  <div>
                    <div class="label bg-[rgba(2, 82, 217, 0.1)] mb-5 mr-10 inline-block text-blue">{{ $t('在售列表均价') }}</div>
                    <div :class="item.beforeChangeTarget * 1 < item.changeTarget * 1 ? 'text-red' : 'text-green'">
                      <span class="text-gray">{{ item.beforeChangeTarget * 1 < item.changeTarget * 1 ? $t('上涨') : $t('下跌') }}</span>
                      {{ (item.changeTarget - item.beforeChangeTarget).toFixed(2) }} ({{ ((item.changeTarget / item.beforeChangeTarget - 1) * 100).toFixed(2) }}%)
                      <span class="text-gray">{{ item.changeType == 1 || item.changeType == 3 ? moneyUnit : $t('件') }}</span>
                    </div>
                  </div>
                  <template #content>
                    <div class="px-10 py-10">
                      <div>{{ $t('原在售列表均价') }} : {{ item.beforeChangeTarget }}</div>
                      <div>{{ $t('现在售列表均价') }} : {{ item.changeTarget }}</div>
                    </div>
                  </template>
                </mytooltip>
              </div>
            </div>
            <div class="dot"></div>
            <div class="left-line"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import mytooltip from '~/components/common/mytooltip.vue'
const { t } = useI18n()
const $t = t
const moneyUnit = ref(monetaryUnit())
const props = defineProps({
  changeData: Object
})
const getChangeText = item => {
  const type = item?.changeType || 1
  if (type === 1) {
    return $t('在售价')
  } else if (type === 2) {
    return $t('在售数')
  } else if (type === 3) {
    return $t('求购价')
  } else if (type === 4) {
    return $t('求购数')
  }
}
const getChangeDirection = item => {
  const upText = $t('上涨')
  const downText = $t('下跌')
  const type = item?.changeType || 1
  const result = {}
  if (type === 1) {
    result.text = item.sellPrice > item.beforeSellPrice ? upText : downText
    result.direcation = item.sellPrice > item.beforeSellPrice ? 'up' : 'down'
    result.diff = (item.sellPrice - item.beforeSellPrice).toFixed(2)
    result.diffRatio = (((item.sellPrice - item.beforeSellPrice) / item.beforeSellPrice) * 100).toFixed(2)
  } else if (type === 2) {
    result.text = item.sellCount > item.beforeSellCount ? $t('增加') : $t('减少')
    result.direcation = item.sellCount > item.beforeSellCount ? 'up' : 'down'
    result.diff = (item.sellCount - item.beforeSellCount).toFixed(2)
    result.diffRatio = (((item.sellCount - item.beforeSellCount) / item.beforeSellCount) * 100).toFixed(2)
  } else if (type === 3) {
    result.text = item.purchasePrice > item.beforePurchasePrice ? upText : downText
    result.direcation = item.purchasePrice > item.beforePurchasePrice ? 'up' : 'down'
    result.diff = (item.purchasePrice - item.beforePurchasePrice).toFixed(2)
    result.diffRatio = (((item.purchasePrice - item.beforePurchasePrice) / item.beforePurchasePrice) * 100).toFixed(2)
  } else if (type === 4) {
    result.text = item.purchaseCount > item.beforePurchaseCount ? $t('增加') : $t('减少')
    result.direcation = item.purchaseCount > item.beforePurchaseCount ? 'up' : 'down'
    result.diff = (item.purchaseCount - item.beforePurchaseCount).toFixed(2)
    result.diffRatio = (((item.purchaseCount - item.beforePurchaseCount) / item.beforePurchaseCount) * 100).toFixed(2)
  }
  return result
}

const formatDayText = item => {
  const todayStart = dayjs().startOf('day').unix()
  const yesterdayStart = dayjs().subtract(1, 'day').startOf('day').unix()
  const beforedayStart = dayjs().subtract(2, 'day').startOf('day').unix()
  if (item.changeTime >= todayStart) {
    return $t('今天') + ' ' + dayjs(item.changeTime * 1000).format('HH:mm')
  } else if (item.changeTime >= yesterdayStart) {
    return $t('昨天') + ' ' + dayjs(item.changeTime * 1000).format('HH:mm')
  } else if (item.changeTime >= beforedayStart) {
    return $t('前天') + ' ' + dayjs(item.changeTime * 1000).format('HH:mm')
  } else {
    return dayjs(item.changeTime * 1000).format('YYYY-MM-DD HH:mm')
  }
  console.log('todayStart', todayStart, yesterdayStart, beforedayStart)
}
</script>

<style lang="scss">
.right-container {
  .item-change-item {
    .label {
      padding: 2px 5px;
      border-radius: 4px;
      font-size: 12px;
      color: #0252d9;
      background-color: rgba(2, 82, 217, 0.1);
    }
    .dot {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: #fff;
      border: 3px solid #0252d9;
      position: absolute;
      left: -27.5px;
      top: 23px;
      z-index: 2;
    }
    .left-line {
      width: 1px;
      height: 100%;
      background-color: #eff1f5;
      position: absolute;
      left: -20.5px;
      top: 25px;
      z-index: 1;
    }
  }
}
</style>
